import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import breakPoints from '../helpers/breakPoints';
import { generateUrl } from '../helpers/cloudinary';
import { Layout, StyledHeroImage, MultiLineText } from '../components';

const Heading = styled.h1`
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 1rem;
`;

const Content = styled.div`
    max-width: 75rem;
    margin: auto;
    padding: 1rem;
    color: rgb(81, 81, 81);
`;

const SectionContainer = styled.div`
    display: flex;
    flex: 1;
    margin-top: 2rem;
    text-align: left;
    align-items: center;
    font-size: 1.4rem;

    @media (max-width: ${breakPoints.mobile - 1}px) {
        flex-direction: column;
    }
`;

const ColumnImg = styled.img`
    width: 50%;

    @media (max-width: ${breakPoints.mobile - 1}px) {
        width: 100%;
    }
`;

const ColumnHeading = styled(MultiLineText)`
    width: 50%;
    margin: 0 1rem;

    @media (max-width: ${breakPoints.mobile - 1}px) {
        width: 100%;
    }
`;

const StyledSection = ({ image, index, heading }) => {
    if (index % 2) {
        return (
            <SectionContainer>
                <ColumnImg src={generateUrl(image)} alt="test" />
                <ColumnHeading description={heading} />
            </SectionContainer>
        );
    }
    return (
        <SectionContainer>
            <ColumnHeading description={heading} />
            <ColumnImg src={generateUrl(image)} alt="test" />
        </SectionContainer>
    );
};

StyledSection.propTypes = {
    image: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    heading: PropTypes.string.isRequired,
};

export const AboutUsPageTemplate = ({ image, sections, heading, subHeading }) => (
    <>
        <StyledHeroImage vh={60} image={image} className="hero" />
        <Content>
            <Heading>{heading}</Heading>
            <MultiLineText description={subHeading} />
            {sections.map((section, index) => (
                <StyledSection image={section.image} index={index} heading={section.heading} />
            ))}
        </Content>
    </>
);

AboutUsPageTemplate.propTypes = {
    image: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string.isRequired,
            heading: PropTypes.string.isRequired,
            subHeading: PropTypes.string.isRequired,
            sectionLink: PropTypes.shape({
                linkText: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
            }),
        })
    ),
};

const AboutUsPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout title={frontmatter.title} description={frontmatter.description} image={frontmatter.image}>
            <AboutUsPageTemplate
                image={frontmatter.image}
                heading={frontmatter.heading}
                subHeading={frontmatter.subHeading}
                sections={frontmatter.sections}
            />
        </Layout>
    );
};

AboutUsPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired,
                heading: PropTypes.string.isRequired,
                subHeading: PropTypes.string.isRequired,
                sections: PropTypes.arrayOf(
                    PropTypes.shape({
                        image: PropTypes.string.isRequired,
                        heading: PropTypes.string.isRequired,
                        subHeading: PropTypes.string.isRequired,
                        sectionLink: PropTypes.shape({
                            linkText: PropTypes.string.isRequired,
                            link: PropTypes.string.isRequired,
                        }),
                    })
                ),
            }),
        }),
    }),
};

export default AboutUsPage;

export const pageQuery = graphql`
    query AboutUsPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "about-us-page" } }) {
            frontmatter {
                title
                description
                image
                heading
                subHeading
                sections {
                    image
                    heading
                    subHeading
                    sectionLink {
                        linkText
                        link
                    }
                }
            }
        }
    }
`;
